<template>
  <div id="app">
    <div class="logo" style="z-index:100">
      <img alt="my-claro" src="./assets/images/logo_myclaro.png" />
    </div>
    <!-- <div id="nav-menu" v-show="$store.state.navMenuVisible === true"></div> -->
    <div :class="{ hidden: $store.state.navMenuVisible === false && windowWidth < 768 && $route.path == '/' }"
      id="nav-menu" v-show="isOnSite == false">
      <ul class="list-menu-nav">
        <li>
          <div class="img-div" @click="$router.push('/')">
            <Pencil v-if="$route.path == '/'" fillColor="#f5821f" :size="32" />
            <Pencil v-else :size="32" />
          </div>
        </li>
        <li>
          <div class="img-div" @click="loginDialogVisible = true">
            <Account v-if="userIsGuest" :size="32" />
            <Account v-else fillColor="#f5821f" :size="32" />
          </div>
        </li>
        <li v-if="!userIsGuest">
          <div class="img-div" @click="$router.push('/carrello')">
            <el-badge :value="rowNumber" class="badge">
              <Cart :size="32" fillColor="#f5821f" v-if="$route.path == '/carrello'" />
              <Cart :size="32" v-else />
            </el-badge>
          </div>
        </li>
        <li v-if="!userIsGuest">
          <div class="img-div" @click="$router.push('/storico')">
            <History v-if="$route.path == '/storico'" :size="32" fillColor="#f5821f" />
            <History v-else :size="32" />
          </div>
        </li>
      </ul>
    </div>
    <div id="router-nav">
      <!-- Use keep-alive to maintain the state of all elements when navigating between views -->
      <!-- This allows the Canvas3D component not to be reinitialized every time the user goes back to the configurator page -->
      <keep-alive>
        <router-view></router-view>
      </keep-alive>
    </div>
    <div v-if="showPopUp" class="popup">
      <div class="container-popup">
        <div class="text-popup uppercase">
          <h1>{{ $t("message.Attenzione") }}</h1>
          <p>
            {{
              $t("message.A_causa_del_potenziamento_dei_nostri_sistemi,_garantiamo_l*evasione_degli_ordini_effettuati_entro_il_12_dicembre**_Tutti_gli_ordini_ricevuti_successivamente,_saranno_presi_in_carico_senza_alcuna_omissione**_Le_spedizioni_riprenderanno_regolarmente_dal_07/01,_quando_torneremo_alla_piena_operatività_con_un_sistema_ancora_più_efficiente,_pensato_per_servirti_il_meglio**")
            }}
          </p>
        </div>
        <div class="button-popup">
          <div class="checkbox-popup">
            <label>
              <input type="checkbox" v-model="dontShowAgain" /> Non mostrare più
            </label>
          </div>
          <div class="buttons">
            <button @click="closePopUp">Chiudi</button>
          </div>
        </div>
      </div>
    </div>
    <div v-if="isInDateRange" class="button-alert-popup uppercase" @click="changePopUp">
      <div>
        {{ $t("message.Avviso") }} !
      </div>
    </div>
    <login-dialog :visible="loginDialogVisible" @close="loginDialogVisible = false" />
  </div>
</template>

<script>
import LoginDialog from "./components/LoginDialog.vue";
// eslint-disable-next-line no-unused-vars
import { mapGetters, mapActions, mapState, mapMutations } from "vuex";

import Pencil from "vue-material-design-icons/PencilOutline.vue";
import Account from "vue-material-design-icons/Account.vue";
import Cart from "vue-material-design-icons/CartOutline.vue";
import History from "vue-material-design-icons/History.vue";

export default {
  // props per visualizzare tasto aggiungi al carrello o menu di navigazione
  // impostata su index.html così:
  // <div id="app" data-site="true"></div> per non visualizzare
  props: ["on_site"],
  components: { LoginDialog, Cart, Pencil, Account, History },
  data() {
    return {
      loginDialogVisible: false,
      windowWidth: window.innerWidth,
      showPopUp: false,
      dontShowAgain: false,
      isInDateRange: false,
    };
  },
  mounted: async function () {
    const dontShow = localStorage.getItem("dontShow");
    const startDate = new Date("2024-12-01"); // Inizio intervallo
    const endDate = new Date("2025-01-07"); // Fine intervallo
    const currentDate = new Date();

    this.isInDateRange = currentDate >= startDate && currentDate <= endDate;
    if (this.isInDateRange && (!dontShow || dontShow === "false")) {
      this.showPopUp = true;
    }
    await this.getUserData();
    console.log(this.on_site);
    if (this.on_site === "true") {
      this.setIsOnSite(true);
    } else {
      this.setIsOnSite(false);
    }
  },
  methods: {
    // ...mapActions("user", ["getUserData"]),
    getUserData() {
      this.$store.dispatch("configurator/user/getUserData");
    },
    closePopUp() {
      // Salva la preferenza dell'utente nel localStorage
      if (this.dontShowAgain) {
        localStorage.setItem("dontShow", "true");
      }
      this.showPopUp = false;
    },
    changePopUp() {
      this.showPopUp = !this.showPopUp; // Cambia lo stato del popup
    },
    ...mapMutations(["setIsOnSite"]),
  },
  computed: {
    // ...mapGetters("user", { userIsGuest: "isGuest" }),
    userIsGuest() {
      return this.$store.getters["configurator/user/isGuest"];
    },
    // ...mapGetters("cart", ["rowNumber"]),
    rowNumber() {
      return this.$store.getters["configurator/cart/rowNumber"];
    },
    ...mapState(["isOnSite"]),
  },
  name: "App",
};
</script>

<style lang="less">
#app {
  // font-family: Avenir, Helvetica, Arial, sans-serif;
  position: relative;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  min-height: 100vh;
  overflow: hidden;

  .uppercase {
    text-transform: uppercase;
  }

  .popup {
    z-index: 1000;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 35%;
    height: 30%;
    background-color: black;
    color: white;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.5);

    .container-popup {
      display: flex;
      gap: 1em;
      flex-direction: column;
      justify-content: space-between;

      .text-popup {
        text-align: center;

        h1 {
          margin: 0;
          color: #f5821f;
        }

        p {
          margin: 10px 0;
        }
      }

      .button-popup {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: center;
        gap: 2em;

        .checkbox-popup {
          cursor: pointer;

          label {
            color: white;
            font-size: 14px;
          }
        }

        .buttons {
          button {
            padding: 10px 20px;
            border: none;
            background-color: #f5821f;
            color: white;
            font-size: 16px;
            cursor: pointer;
            border-radius: 5px;
            transition: background-color 0.3s;
          }

          button:hover {
            background-color: #c0392b;
          }
        }
      }
    }
  }

  .button-alert-popup {
    border-radius: 10px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
    background-color: #f5821f;
    color: white;
    padding: 10px;
    display: flex;
    gap: 1em;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 1%;
    right: 50%;
    transform: translate(50%);
    cursor: pointer;
  }

  @media (max-width: 1200px) {
    .logo {
      img {
        height: 100px;
      }
    }
  }

  @media (max-width: 992px) {
    .logo {
      img {
        height: 80px;
      }
    }
  }

  @media (min-width: 1201px) {
    .logo {
      img {
        height: 150px;
      }
    }
  }

  .logo {
    position: fixed;
    left: 25px;
    top: 15px;
  }

  #nav-menu {
    height: 100px;
    position: absolute;
    z-index: 1;

    &.hidden {
      display: none;
    }

    .list-menu-nav {
      position: fixed;
      top: 30px;
      right: 30px;
      list-style-type: none;
      display: flex;
      justify-content: flex-end;
      margin: 0px;
      padding: 0px;
      width: 100%;

      li {
        .img-div {
          height: 50px;
          padding: 0px 5px;
          margin: 0px 5px;
          cursor: pointer;
        }

        img {
          height: 30px;
          // &:hover {
          //   filter: drop-shadow(@--color-primary);
          // }
        }

        span {
          padding-top: 5px;
          color: @--color-primary;
        }
      }
    }
  }

  #router-nav>* {
    height: 100vh;
    // padding-bottom: 65px;
    // -webkit-overflow-scrolling: touch;
  }
}
</style>
